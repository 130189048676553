import type { PaymentEntity, PurchaseEntity } from '../_entities/_types'
import axiosRequest from '@apis/axios'
import { datadogLogs } from '@datadog/browser-logs'
import { getReturnUrl } from '@utils/stripe/return-url'
import { CheckoutPayloadInterface } from '@shared/interfaces/checkout-payload.interface'
import { isEmptyString } from '@utils/strings'
import { isDefined } from '@utils/connascence'

/**
 * Purchase content by content id
 * In V2 we now need to pass the content price ID to the backend
 * @param request
 */
export default async function purchaseContentByContentIdV2(request: {
  contentId: string
  channelId: string
  price?: number
  contentPriceId?: string
  promotionCode?: string
  isCouponsForViewersActive?: boolean
  continueUrl?: string
}): Promise<PaymentEntity | PurchaseEntity> {
  const {
    contentId,
    channelId,
    price,
    contentPriceId,
    promotionCode,
    isCouponsForViewersActive,
    continueUrl,
  } = request

  const _continue =
    isDefined(continueUrl) && !isEmptyString(continueUrl)
      ? continueUrl
      : getReturnUrl(window.location.href)

  datadogLogs.logger.info('Making a content purchase', { request })

  // Build the payload
  const dataPayload: CheckoutPayloadInterface = {
    contentId,
    price,
    channelId,
    contentPriceId,
    returnUrl: _continue,
  }

  // If there is a promotion code, add it to the payload
  if (promotionCode) {
    dataPayload.promotionCode = promotionCode
  }

  // If the flag isCouponsForViewersActive is true, we need to use the updated endpoint and version
  let url = `checkout/V2`
  let version = '3'
  if (isCouponsForViewersActive) {
    url = `checkout`
    version = '5'
  }

  const response = (await axiosRequest(
    'billing',
    {
      method: 'POST',
      url,
      data: dataPayload,
    },
    true,
    version,
  )) as PaymentEntity | PurchaseEntity

  datadogLogs.logger.info('Successful content purchase', {
    response,
    request,
  })

  return response
}
